<template>
  <div class="payment-failed">
    <div class="payment-failed__text">
      <AlertIcon class="payment-failed__icon" />
      Payment failed
    </div>

    <Button @click="$router.push({ name: HOME_ROUTE_NAME })">
      Go back
    </Button>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import AlertIcon from '@/assets/icons/alert-icon.svg';
import Button from '@/components/LogoMakerButton.vue';
import { HOME_ROUTE_NAME } from '@/router';
import { logEvent } from '@/api/AmplitudeApi';
import { LOGO_MAKER_ENTER_PAYMENT_FAILED } from '@/constants/amplitude';

onMounted(() => {
  logEvent({ name: LOGO_MAKER_ENTER_PAYMENT_FAILED });
});
</script>

<style lang="scss" scoped>
.payment-failed {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.33;

  &__text {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 32px;
  }

  &__icon {
    width: 30px;
    fill: var(--danger);
  }
}
</style>
