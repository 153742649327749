export const recommendedFonts = [
  'Playfair Display',
  'Raleway',
  'Montserrat',
  'Lato',
  'Roboto',
  'Pacifico',
  'Open Sans',
  'Oswald',
  'Dancing Script',
  'Great Vibes',
  'Abril Fatface',
  'Bebas Neue',
  'Merriweather',
  'Josefin Sans',
  'Lobster',
  'Amatic SC',
  'Poppins',
  'Quicksand',
  'Ubuntu',
  'Cabin',
  'Source Sans Pro',
  'Bangers',
  'Cinzel',
  'Dosis',
  'Fjalla One',
  'Inconsolata',
  'Lobster Two',
  'Muli',
  'Noto Serif',
  'Overpass',
  'Pinyon Script',
  'Quattrocento',
  'Questrial',
  'Satisfy',
  'Shadows Into Light',
  'Special Elite',
  'Titillium Web',
  'Varela Round',
  'Yanone Kaffeesatz',
  'Arvo',
  'Bree Serif',
  'Comfortaa',
  'Didact Gothic',
  'Indie Flower',
  'Kaushan Script',
  'Nunito',
  'Poiret One',
  'Rokkitt',
  'Tangerine',
  'Vollkorn',
];
