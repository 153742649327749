import { ref } from 'vue';
import { defineStore } from 'pinia';
// import { useStorage } from '@vueuse/core';

export const useAppStore = defineStore('app', () => {
  const isLoading = ref(false);

  // TODO - user gets error when generating a website from this link in some cases. Enable the banner after fixing.
  // const isAiBuilderBannerOpen = useStorage('is-ai-builder-banner-open', true);
  const isAiBuilderBannerOpen = false;

  return {
    isLoading,
    isAiBuilderBannerOpen,
  };
});
