<script setup>
import { ref, computed, onMounted } from 'vue';
import { useLogo } from '@/use/useLogo';
import { useUserStore } from '@/stores/user';
import SettingsIcon from '@/assets/icons/settingsV2.svg';
import CheckIcon from '@/assets/icons/check-round.svg';
import ArrowDownIcon from '@/assets/icons/arrow-downV2.svg';
import Button from '@/components/LogoMakerButton.vue';
import Loader from '@/components/LogoMakerLoader.vue';
import { ICON_TYPES_V2, ABSTRACT_ICON } from '@/constants/iconTypes';
import ToggleInput from '@/components/ToggleInput.vue';
import Popup from '@/components/LogoMakerPopup.vue';
import { logEvent } from '@/api/AmplitudeApi';
import { ENTER_SETTINGS, COLORFUL_OPTION_CHANGED } from '@/constants/amplitude';

const emit = defineEmits(['submit']);

const { isAiLogosBeingGenerated } = useLogo();
const userStore = useUserStore();

const isIconTypeSelectPopupOpen = ref(false);
const isGeneratorSettingsPopupOpen = ref(false);
const logoDescriptionInputRef = ref(null);

const selectedColorfulMode = computed(
  () => userStore.userSettings.isColorfulMode,
);

const selectedIconType = computed(
  () =>
    ICON_TYPES_V2[userStore.userSettings.iconTypeIdAutoMode] ??
    ICON_TYPES_V2[ABSTRACT_ICON],
);

onMounted(() => {
  logoDescriptionInputRef.value.focus();
});

const handleGenerateClick = () => {
  emit('submit');
};

const handleColorfulModeToggle = (value) => {
  logEvent({
    name: COLORFUL_OPTION_CHANGED,
    properties: { is_toggled_on: value },
  });
  userStore.userSettings.isColorfulMode = value;
};

const handleGeneratorSettingsButtonClick = () => {
  isGeneratorSettingsPopupOpen.value = !isGeneratorSettingsPopupOpen.value;

  if (isGeneratorSettingsPopupOpen.value) {
    logEvent({ name: ENTER_SETTINGS });
  }
};

const selectIconType = (iconType) => {
  userStore.userSettings.iconTypeIdAutoMode = iconType;
  isIconTypeSelectPopupOpen.value = false;
};
</script>

<template>
  <div class="generator-bar">
    <input
      ref="logoDescriptionInputRef"
      v-model="userStore.userSettings.brandDescription"
      class="generator-bar__input"
      type="text"
      maxlength="700"
      data-qa="logomaker-generatelogoform-input-logodescription"
      placeholder="Cup of coffee with a heart sign"
      data-hj-allow
    >
    <div class="generator-bar__settings">
      <button
        ref="generatorStyleButtonRef"
        class="generator-bar__symbol-style"
        data-qa="logomaker-generatelogoform-button-styleselect"
        :class="{
          'generator-bar__symbol-style--active': isIconTypeSelectPopupOpen,
        }"
        @click="isIconTypeSelectPopupOpen = !isIconTypeSelectPopupOpen"
      >
        <span class="generator-bar__symbol-style-text">
          {{ selectedIconType.name }}
        </span>
        <ArrowDownIcon class="generator-bar__symbol-style-arrow" />
      </button>
      <button
        ref="generatorSettingsButtonRef"
        data-qa="logomaker-generatelogoform-button-togglesettingspopup"
        class="generator-bar__settings-button"
        :class="{
          'generator-bar__settings-button--active':
            isGeneratorSettingsPopupOpen,
        }"
        @click="handleGeneratorSettingsButtonClick"
      >
        <SettingsIcon class="generator-bar__settings-icon" />
      </button>
    </div>
    <Button
      class="generator-bar__button"
      data-qa="logomaker-generatelogoform-button-generate"
      size="xlarge"
      :disabled="!userStore.userSettings.brandDescription"
      @click="handleGenerateClick"
    >
      <Loader v-if="isAiLogosBeingGenerated" />
      <template v-else>
        <div class="generator-bar__button-container">
          <div class="generator-bar__button-generate">
            Create
          </div>
          <div class="generator-bar__button-credit">
            - 1 Credit
          </div>
        </div>
      </template>
    </Button>
  </div>
  <Popup
    v-if="isGeneratorSettingsPopupOpen"
    :target-ref="$refs?.generatorSettingsButtonRef"
    placement="bottom"
    :offset="12"
    @click-outside="isGeneratorSettingsPopupOpen = false"
  >
    <div class="generator-settings-popup">
      <p class="generator-settings__title">
        Make logo colorful
      </p>
      <ToggleInput
        id="generator-color-input"
        :model-value="selectedColorfulMode"
        @update:model-value="handleColorfulModeToggle"
      />
    </div>
  </Popup>
  <Popup
    v-if="isIconTypeSelectPopupOpen"
    :target-ref="$refs?.generatorStyleButtonRef"
    placement="bottom"
    :offset="12"
    @click-outside="isIconTypeSelectPopupOpen = false"
  >
    <div class="generator-style-popup">
      <ul class="generator-style-popup__list">
        <li
          v-for="(icon, index) in ICON_TYPES_V2"
          :key="`icon-item-${index}`"
          class="generator-style-popup__item"
        >
          <button
            data-qa="logomaker-generatelogoform-button-setlogostyle"
            class="generator-style-popup__button"
            :class="{
              'generator-style-popup__button--active':
                icon.id === selectedIconType.id,
            }"
            @click="selectIconType(icon.id)"
          >
            <p class="generator-style-popup__icon-name">
              {{ icon.name }}
            </p>
            <CheckIcon
              v-if="icon.id === selectedIconType.id"
              class="generator-bar__check-icon"
            />
          </button>
        </li>
      </ul>
    </div>
  </Popup>
</template>

<style lang="scss" scoped>
.generator-bar {
  position: relative;
  display: flex;
  gap: 8px;
  margin: 0 auto;
  width: 574px;
  max-width: 100%;
  background-color: var(--primary-light);
  border-radius: 16px;
  font-size: 14px;
  align-items: center;
  margin-bottom: 16px;
  padding: 8px;
  width: auto;

  &__input {
    background: url(@/assets/icons/sparkles-icon.svg) no-repeat scroll 16px 16px;
    background-color: var(--light);
    border-radius: 8px;
    width: 400px;
    height: 56px;
    padding: 14px 16px 14px 56px;
    box-sizing: border-box;
    text-overflow: ellipsis;
    border: 1px solid var(--gray-border);
    transition:
      border 200ms ease-out,
      box-shadow 200ms ease-out;

    &:hover,
    &:focus {
      border: 1px solid var(--primary);
      box-shadow: 0 0 0 1px var(--primary);
    }
  }

  &__settings {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__symbol-style {
    display: flex;
    width: 160px;
    height: 56px;
    align-items: center;
    padding: 16px;
    border-radius: 8px;
    background-color: var(--light);
    border: 1px solid var(--gray-border);
    transition:
      border 200ms ease-out,
      box-shadow 200ms ease-out;
    cursor: pointer;

    &:hover,
    &:focus,
    &--active {
      border: 1px solid var(--primary);
      box-shadow: 0 0 0 1px var(--primary);
    }
  }

  &__symbol-style-text {
    margin-right: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__settings-button {
    height: 56px;
    width: 56px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid var(--gray-border);
    transition:
      border 200ms ease-out,
      box-shadow 200ms ease-out;
    background-color: var(--light);
    cursor: pointer;

    &:hover,
    &--active,
    &:focus {
      border: 1px solid var(--primary);
      box-shadow: 0 0 0 1px var(--primary);
    }
  }

  &__settings-icon {
    width: 24px;
  }

  &__button {
    min-width: 150px;
    padding: 12px 50px;
    height: 56px;
    border-radius: 8px;
  }

  &__button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__button-credit {
    font-size: 12px;
    line-height: 1.33;
    color: var(--gray-border);
  }
}

.generator-settings-popup {
  background-color: var(--light);
  border: 1px solid var(--gray-border);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  width: 240px;
  justify-content: space-between;
  box-shadow: 0px 0px 12px rgba(29, 30, 32, 0.16);
  gap: 8px;
}

.generator-style-popup {
  background-color: var(--light);
  border: 1px solid var(--gray-border);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 12px rgba(29, 30, 32, 0.16);

  &__item {
    list-style: none;
  }

  &__list {
    padding: 8px;
    max-width: 200px;
    max-height: 328px;
    overflow-y: auto;
  }

  &__button {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    text-align: left;
    font-size: 14px;
    padding: 8px;
    width: 100%;
    border-radius: 4px;
    transition: background-color 200ms ease-out;

    &:hover,
    &--active {
      background-color: var(--gray-light);
    }
  }
}

@media screen and (max-width: 920px) {
  .generator-bar {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;

    &__settings {
      width: 100%;
    }

    &__symbol-style {
      width: 100%;
    }

    &__button {
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .generator-bar {
    width: 100%;
    &__input {
      width: 100%;
    }
  }
}
</style>
