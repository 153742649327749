import { IS_NEW_USER } from '@/constants/localStorage';

export const openWebsiteBuilderOffer = () => {
  const isNewUser = localStorage.getItem(IS_NEW_USER) === 'true';
  const utmParamName = isNewUser ? 'logomaker-new' : 'logomaker';

  window.open(
    `${
      import.meta.env.VITE_HOSTINGER_HOMEPAGE_URL
    }/website-builder?utm_source=${utmParamName}&utm_medium=${utmParamName}&utm_campaign=${utmParamName}#pricing`,
    '_blank',
  );
};
