<script setup>
import { onMounted, watch, ref } from 'vue';
import { useRouter } from 'vue-router';
import { logEvent } from '@/api/AmplitudeApi';
import { useAppStore } from '@/stores/app';
import { useUserStore } from '@/stores/user';
import { useLogosStore } from '@/stores/logos';
import { useChargesStore } from '@/stores/charges';
import { useLogo } from '@/use/useLogo';
import { useTextToSvg } from '@/use/useTextToSvg';
import SparklesIcon from '@/assets/icons/sparkling.svg';
import MagicPenIcon from '@/assets/icons/pencil.svg';
import AppHeader from '@/components/AppHeader.vue';
import MainViewGeneratorTab from '@/components/MainViewGeneratorTab.vue';
import MainViewCreateYourselfTab from '@/components/MainViewCreateYourselfTab.vue';
import { db } from '@/db';
import { ENTER_HOME, MODE_CHANGE } from '@/constants/amplitude';
import {
  MAIN_VIEW_TAB_AUTO,
  MAIN_VIEW_TAB_MANUAL,
  MAIN_VIEW_TABS,
} from '@/constants/mainView';
import { useCookies } from '@vueuse/integrations/useCookies';
import { IS_NEW_USER, IS_FEEDBACK_REQUESTED } from '@/constants/localStorage';

const MEDIA_MOBILE_BREAKPOINT = 704;

const appHeaderRef = ref(null);

const router = useRouter();
const userStore = useUserStore();
const logosStore = useLogosStore();
const appStore = useAppStore();
const chargesStore = useChargesStore();
const { mainViewActiveTabId } = useLogo();
const { initGoogleFonts } = useTextToSvg();
const cookies = useCookies(['hostingerDeviceId']);

const isMobile = window.screen.width <= MEDIA_MOBILE_BREAKPOINT;

const { query } = router.currentRoute.value;

const initializeLogoMaker = async () => {
  appStore.isLoading = true;

  if (
    !userStore.userSettings.brandName &&
    !userStore.userSettings.brandDescription
  ) {
    appStore.isLoading = false;
  }

  const [logos] = await Promise.all([
    db.logos.toCollection().reverse().sortBy('clientTimestamp'),
    initGoogleFonts(),
    chargesStore.getCreditCharges(),
  ]);

  logosStore.logosList = [...logos];

  appStore.isLoading = false;

  // Flag users who are visiting Hostinger first time through logo maker
  // Flag will be used in Amplitude/GA tracking
  const isNewUser =
    !localStorage.getItem(IS_NEW_USER) && !cookies.get('hostingerDeviceId');
  if (!localStorage.getItem(IS_NEW_USER)) {
    localStorage.setItem(IS_NEW_USER, isNewUser);
  }

  if (!MAIN_VIEW_TABS.includes(mainViewActiveTabId.value)) {
    mainViewActiveTabId.value = MAIN_VIEW_TAB_AUTO;
  }

  logEvent({
    name: ENTER_HOME,
    properties: {
      creation_location: query.ref,
      is_mobile: isMobile ? 1 : 0,
      is_new_user: isNewUser ? 1 : 0,
      mode: mainViewActiveTabId.value,
      paid_credit_status: userStore.creditsBalance ?? 0,
    },
  });
  if (query.login || query.ref === 'hostinger-landing') {
    window.dataLayer.push({ event: 'LogoMakerLogin' });
  }
};

const handleDownloadClick = () => {
  const isFeedbackRequested = localStorage.getItem(IS_FEEDBACK_REQUESTED);

  if (!isFeedbackRequested) {
    // Delay the feedback modal for a better UX
    setTimeout(() => {
      appHeaderRef.value.isSendFeedbackModalOpen = true;

      localStorage.setItem(IS_FEEDBACK_REQUESTED, true);
    }, 2000);
  }
};

onMounted(() => {
  initializeLogoMaker();
});

watch(mainViewActiveTabId, (newTabId) => {
  logEvent({ name: MODE_CHANGE, properties: { mode_type: newTabId } });
});
</script>

<template>
  <div class="main">
    <AppHeader ref="appHeaderRef">
      <div class="header__tabs">
        <div
          class="header__tab"
          :class="{ active: mainViewActiveTabId === MAIN_VIEW_TAB_AUTO }"
          @click="mainViewActiveTabId = MAIN_VIEW_TAB_AUTO"
        >
          <SparklesIcon class="header__tab-icon" />
          <span class="header__tab-text">Create with AI</span>
        </div>
        <div
          class="header__tab"
          :class="{ active: mainViewActiveTabId === MAIN_VIEW_TAB_MANUAL }"
          @click="mainViewActiveTabId = MAIN_VIEW_TAB_MANUAL"
        >
          <MagicPenIcon class="header__tab-icon" />
          <span class="header__tab-text">Create yourself</span>
        </div>
      </div>
    </AppHeader>

    <KeepAlive>
      <MainViewGeneratorTab
        v-if="MAIN_VIEW_TAB_AUTO === mainViewActiveTabId"
        @download-logo="handleDownloadClick"
      />
    </KeepAlive>
    <KeepAlive>
      <MainViewCreateYourselfTab
        v-if="mainViewActiveTabId === MAIN_VIEW_TAB_MANUAL"
        @download-logo="handleDownloadClick"
      />
    </KeepAlive>
  </div>
</template>

<style scoped lang="scss">
.main {
  display: flex;
  position: relative;
  flex-direction: column;
  z-index: 1;
  height: 100%;
}

.header {
  &__tabs {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    border: 1px solid var(--gray-border);
    border-radius: 8px;
    display: flex;
    width: fit-content;
    background: var(--gray-light);
  }

  &__tab {
    width: 224px;
    padding: 11px 16px;
    line-height: 1.5;
    border-radius: 8px;
    color: var(--gray);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;

    &.active {
      background: var(--light);
      color: var(--dark);
      fill: var(--dark);
    }

    &.active .header__tab-icon {
      fill: var(--dark);
    }
  }

  &__tab-icon {
    display: none;
    margin-right: 6px;
    fill: var(--gray);
    width: 18px;
  }
}

@media screen and (max-width: 1012px) {
  .header {
    &__tab {
      width: auto;
      padding: 10px 20px;
    }

    &__tab-icon {
      display: block;
      margin-right: 0;
      height: 24px;
      width: 24px;
    }

    &__tab-text {
      display: none;
    }
  }
}
</style>
