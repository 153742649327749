import { authHttpService } from '@/api/authHttpService';
import httpService from '@/api/httpService';

const URL = `${import.meta.env.VITE_API_H_SSO}/v1/auth`;

export const refreshAuthToken = () =>
  authHttpService.post<{ token: string }>(`${URL}/refresh`);

export const generateToken = (app: string) =>
  httpService.post<{ data: { token: string } }>(
    `${URL}/generate-token`,
    {
      target_application: app,
    },
    {
      withCredentials: false,
    },
  );
