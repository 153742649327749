import preciseIconImage from '@/assets/images/precise-icon.png';
import preciseIconLineartImage from '@/assets/images/precise-icon-lineart.png';
import preciseIcon3dImage from '@/assets/images/precise-icon-3d.png';
import abstractIconImage from '@/assets/images/abstract-icon.png';
import abstractShapeImage from '@/assets/images/abstract-shape.png';
import abstractIcon3dImage from '@/assets/images/abstract-icon-3d.png';
import letterformIconImage from '@/assets/images/lettermark.png';
import letterformIcon3dImage from '@/assets/images/lettermark-3d.png';

export const PRECISE_ICON_DEFAULT = 'precise-icon-default';
export const PRECISE_ICON_LINEART = 'precise-icon-lineart';
export const PRECISE_ICON_3D = 'precise-icon-3d';
export const ABSTRACT_ICON_DEFAULT = 'abstract-icon-default';
export const ABSTRACT_ICON_LINEART = 'abstract-icon-lineart';
export const ABSTRACT_ICON_3D = 'abstract-icon-3d';
export const ABSTRACT_ICON_SHAPE = 'abstract-icon-shape';
export const LETTERFORM_ICON_DEFAULT = 'letterform-icon-default';
export const LETTERFORM_ICON_LINEART = 'letterform-icon-lineart';
export const LETTERFORM_ICON_3D = 'letterform-icon-3d';

export const ABSTRACT_ICON = 'abstract-icon';
export const CORPORATE_ICON = 'corporate-icon';
export const ECO_FRIENDLY_OR_NATURAL_ICON = 'eco-friendly-or-natural-icon';
export const FUTURISTIC_ICON = 'futuristic-icon';
export const GEOMETRIC_ICON = 'geometric-icon';
export const HAND_DRAWN_OR_ARTISTIC_ICON = 'hand-drawn-or-artistic-icon';
export const MASCOTS_ICON = 'mascots-icon';
export const MINIMALISTIC_ICON = 'minimalistic-icon';
export const MODERN_ICON = 'modern-icon';
export const NEUMORPHIC_ICON = 'neumorphic-icon';
export const LUXURY_OR_ELEGANT_ICON = 'luxury-or-elegant-icon';
export const SPORTY_OR_ATHLETIC_ICON = 'sporty-or-athletic-icon';
export const SIMPLE_ICON = 'simple-icon';
export const TYPOGRAPHY_BASED_ICON = 'typography-based-icon';
export const TECH_OR_DIGITAL_ICON = 'tech-or-digital-icon';
export const VINTAGE_OR_RETRO_ICON = 'vintage-or-retro-icon';

export const ICON_TYPES_V2 = {
  [ABSTRACT_ICON]: {
    id: ABSTRACT_ICON,
    name: 'Abstract',
  },
  [CORPORATE_ICON]: {
    id: CORPORATE_ICON,
    name: 'Corporate',
  },
  [ECO_FRIENDLY_OR_NATURAL_ICON]: {
    id: ECO_FRIENDLY_OR_NATURAL_ICON,
    name: 'Eco-Friendly or Natural',
  },
  [FUTURISTIC_ICON]: {
    id: FUTURISTIC_ICON,
    name: 'Futuristic',
  },
  [GEOMETRIC_ICON]: {
    id: GEOMETRIC_ICON,
    name: 'Geometric',
  },
  [HAND_DRAWN_OR_ARTISTIC_ICON]: {
    id: HAND_DRAWN_OR_ARTISTIC_ICON,
    name: 'Hand-Drawn or Artistic',
  },
  [MASCOTS_ICON]: {
    id: MASCOTS_ICON,
    name: 'Mascots',
  },
  [MINIMALISTIC_ICON]: {
    id: MINIMALISTIC_ICON,
    name: 'Minimalistic',
  },
  [MODERN_ICON]: {
    id: MODERN_ICON,
    name: 'Modern',
  },
  [NEUMORPHIC_ICON]: {
    id: NEUMORPHIC_ICON,
    name: 'Neumorphic',
  },
  [LUXURY_OR_ELEGANT_ICON]: {
    id: LUXURY_OR_ELEGANT_ICON,
    name: 'Luxury or Elegant',
  },
  [SPORTY_OR_ATHLETIC_ICON]: {
    id: SPORTY_OR_ATHLETIC_ICON,
    name: 'Sporty or Athletic',
  },
  [SIMPLE_ICON]: {
    id: SIMPLE_ICON,
    name: 'Simple',
  },
  [TYPOGRAPHY_BASED_ICON]: {
    id: TYPOGRAPHY_BASED_ICON,
    name: 'Typography-Based',
  },
  [TECH_OR_DIGITAL_ICON]: {
    id: TECH_OR_DIGITAL_ICON,
    name: 'Tech or Digital',
  },
  [VINTAGE_OR_RETRO_ICON]: {
    id: VINTAGE_OR_RETRO_ICON,
    name: 'Vintage or Retro',
  },
};

export const ICON_TYPES = {
  [PRECISE_ICON_DEFAULT]: {
    id: PRECISE_ICON_DEFAULT,
    name: 'Precise Icon',
    imageUrl: preciseIconImage,
  },
  [PRECISE_ICON_LINEART]: {
    id: PRECISE_ICON_LINEART,
    name: 'Precise Icon, Lineart',
    imageUrl: preciseIconLineartImage,
  },
  [PRECISE_ICON_3D]: {
    id: PRECISE_ICON_3D,
    name: 'Precise Icon, 3D',
    imageUrl: preciseIcon3dImage,
  },
  [ABSTRACT_ICON_DEFAULT]: {
    id: ABSTRACT_ICON_DEFAULT,
    name: 'Abstract Icon',
    imageUrl: abstractIconImage,
    isSvg: true,
  },
  [ABSTRACT_ICON_SHAPE]: {
    id: ABSTRACT_ICON_SHAPE,
    name: 'Abstract Shape',
    imageUrl: abstractShapeImage,
  },
  [ABSTRACT_ICON_3D]: {
    id: ABSTRACT_ICON_3D,
    name: 'Abstract Icon, 3D',
    imageUrl: abstractIcon3dImage,
  },
  [LETTERFORM_ICON_DEFAULT]: {
    id: LETTERFORM_ICON_DEFAULT,
    name: 'Lettermark Icon',
    imageUrl: letterformIconImage,
    isBeta: true,
    isSvg: true,
  },
  [LETTERFORM_ICON_3D]: {
    id: LETTERFORM_ICON_3D,
    name: 'Lettermark Icon, 3D',
    imageUrl: letterformIcon3dImage,
    isBeta: true,
  },
};
