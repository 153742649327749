import { ref } from 'vue';
import { load } from 'opentype.js';
import { getGoogleFonts } from '@/api/GoogleFontsApi';

const googleFontsList = ref([]);
const openTypeFontObjects = ref({});
const FALLBACK_ROBOTO_FONT_URL =
  'http://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf';

export const useTextToSvg = () => {
  const initGoogleFonts = async () => {
    try {
      const { items } = await getGoogleFonts();
      googleFontsList.value = items;
    } catch (error) {
      console.error(error);
    }
  };

  const getSvg = async ({ text, fontFamily, fontSize }) => {
    if (!googleFontsList.value.length) {
      await initGoogleFonts();
    }

    const googleFontFamily = googleFontsList.value.find(
      ({ family }) => fontFamily === family,
    );

    const fontUrl = (
      googleFontFamily?.files?.['700'] ||
      googleFontFamily?.files?.regular ||
      FALLBACK_ROBOTO_FONT_URL
    ).replace(/^(http:|https:)/, '');

    if (!openTypeFontObjects.value[fontFamily]) {
      openTypeFontObjects.value[fontFamily] = await load(fontUrl);
    }

    const textPathToMeasureBoundings = openTypeFontObjects.value[
      fontFamily
    ].getPath(text, 0, 0, fontSize);
    const { x1, x2, y1, y2 } = textPathToMeasureBoundings.getBoundingBox();
    const textWidth = x2 - x1;
    const textHeight = y2 - y1;
    const textPath = openTypeFontObjects.value[fontFamily].getPath(
      text,
      -x1,
      -y1,
      fontSize,
    );
    const textSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${textWidth}" height="${textHeight}" viewBox="0 0 ${textWidth} ${textHeight}">${textPath.toSVG()}</svg>`;

    return {
      svg: textSvg,
      width: textWidth,
      height: textHeight,
    };
  };

  return {
    initGoogleFonts,
    googleFontsList,
    getSvg,
  };
};
