import { ref } from 'vue';
import { nanoid } from 'nanoid';
import tinyColor from 'tinycolor2';
import { sample } from 'lodash';
import { svgAsPngUri } from 'save-svg-as-png';
import { saveAs } from 'file-saver';
import domtoimage from 'dom-to-image';
import JSZip from 'jszip';
import { useStorage } from '@vueuse/core';
import { useTextToSvg } from '@/use/useTextToSvg';
import { useLogosStore } from '@/stores/logos';
import { useUserStore } from '@/stores/user';
import { generateLogos } from '@/api/AiApi';
import { logEvent } from '@/api/AmplitudeApi';
import { dataURLToBlob } from '@/utils/dataURLToBlob';
import {
  CREATE_LOGO,
  DOWNLOAD_LOGO,
  TRY_CREATE_LOGO,
} from '@/constants/amplitude';
import { MAIN_VIEW_TAB_AUTO, AI_MODEL_TYPE_DALLE } from '@/constants/mainView';

const mainViewActiveTabId = useStorage(
  'mainViewActiveTabId',
  MAIN_VIEW_TAB_AUTO,
);
const isAiLogosBeingGenerated = ref(false);
const isPurchaseModalOpen = ref(false);

export const useLogo = () => {
  const logosStore = useLogosStore();
  const userStore = useUserStore();

  const createLogo = async ({
    symbolType,
    symbolData,
    symbolColor = '#000',
    symbolWidth,
    symbolHeight,
    nameText,
    nameColor = '#000',
    nameFontFamily = 'Roboto',
    nameFontSize = 46,
    sloganText,
    sloganColor = '#000',
    sloganFontFamily = 'Roboto',
    sloganFontSize = 20,
    autoLayout,
    metadata,
    clientTimestamp = Math.floor(Date.now() / 1000),
  }) => {
    const { getSvg } = useTextToSvg();

    const { svg: nameSvg, width: nameWidth } = nameText
      ? await getSvg({
          text: nameText,
          fontFamily: nameFontFamily,
          fontSize: nameFontSize,
        })
      : { svg: null, width: null };

    const { svg: sloganSvg, width: sloganWidth } = sloganText
      ? await getSvg({
          text: sloganText,
          fontFamily: sloganFontFamily,
          fontSize: sloganFontSize,
        })
      : { svg: null, width: null };

    return {
      id: nanoid(5),
      autoLayout,
      clientTimestamp,
      metadata,
      elements: [
        {
          id: 'mark',
          type: symbolType,
          width: symbolWidth,
          height: symbolHeight,
          [symbolType]: symbolData,
          color: symbolColor,
        },
        {
          id: 'name',
          type: 'text',
          fontSize: nameFontSize,
          fontFamily: nameFontFamily,
          fontWeight: 700,
          text: nameText,
          svg: nameSvg,
          width: nameWidth,
          color: nameColor,
        },
        {
          id: 'slogan',
          type: 'text',
          fontSize: sloganFontSize,
          fontFamily: sloganFontFamily,
          fontWeight: 700,
          text: sloganText,
          svg: sloganSvg,
          width: sloganWidth,
          color: sloganColor,
        },
      ],
    };
  };

  const createAiLogos = async ({
    brandName = userStore.userSettings.brandName,
    brandSlogan = userStore.userSettings.brandSlogan,
    brandDescription = userStore.userSettings.brandDescription,
    iconType = userStore.userSettings.iconTypeIdAutoMode,
    isColorful = userStore.userSettings.isColorfulMode,
    modelType = AI_MODEL_TYPE_DALLE,
    modeType,
  } = {}) => {
    logEvent({
      name: TRY_CREATE_LOGO,
      properties: { mode_type: modeType },
    });

    if (isAiLogosBeingGenerated.value) {
      return;
    }

    try {
      if (!userStore.creditsBalance) {
        isPurchaseModalOpen.value = true;
        return;
      }

      isAiLogosBeingGenerated.value = true;
      const generatingTimeStart = performance.now();

      const {
        logos: generatedLogos,
        generatedIconDescription,
        generatedIconKeywords,
        balance,
      } = await generateLogos({
        brandDescription: brandDescription,
        iconType,
        modelType,
        isColorful,
      });

      const newLogos = await Promise.all(
        generatedLogos.map(
          ({
            icon,
            iconWidth,
            iconHeight,
            iconFormat,
            logoFont,
            symbolDominantColor,
            logoType,
          }) => {
            const autoLayout = sample(['markLeft', 'markTop']);
            const aspectRatio = iconWidth / iconHeight;
            const iconWidthCalculated = autoLayout === 'markLeft' ? 80 : 100;
            const iconHeightCalculated = iconWidthCalculated / aspectRatio;
            const logoColor = tinyColor(symbolDominantColor).isLight()
              ? '#000000'
              : symbolDominantColor;

            return createLogo({
              symbolType: iconFormat,
              symbolData: icon,
              symbolColor: logoColor,
              symbolWidth: iconWidthCalculated,
              symbolHeight: iconHeightCalculated,
              nameColor: logoColor,
              nameFontFamily: logoFont,
              sloganColor: logoColor,
              sloganFontFamily: logoFont,
              autoLayout,
              metadata: {
                logoType,
                generatedIconDescription,
                generatedIconKeywords,
                iconType,
              },
            });
          },
        ),
      );

      logosStore.logosList = [...newLogos, ...logosStore.logosList];

      if (userStore.creditsBalance) {
        userStore.creditsBalance = balance;
      }

      logosStore.saveLogosToIndexDB();
      logEvent({
        name: CREATE_LOGO,
        properties: {
          name_text: brandName,
          slogan_text: brandSlogan,
          brand_description: brandDescription,
          generated_icon_description: generatedIconDescription,
          generated_icon_keywords: generatedIconKeywords,
          icon_type: iconType,
          generating_time: Math.round(
            (performance.now() - generatingTimeStart) / 1000,
          ),
          mode_type: modeType,
        },
      });
    } catch (error) {
      console.error(error);
      if ([400].includes(error.response?.status)) {
        isPurchaseModalOpen.value = true;
      }
    } finally {
      isAiLogosBeingGenerated.value = false;
    }
  };

  const downloadLogo = async ({
    logoNode,
    faviconNode,
    logoData,
    modeType,
    scale = 1,
  }) => {
    logEvent({
      name: DOWNLOAD_LOGO,
      properties: {
        logo_id: logoData.id,
        name_text: logoData.elements.find((element) => element.id === 'name')
          .text,
        slogan_text: logoData.elements.find(
          (element) => element.id === 'slogan',
        ).text,
        type: logoData?.metadata?.logoType,
        generated_icon_description:
          logoData?.metadata?.generatedIconDescription,
        generated_icon_keywords: logoData?.metadata?.generatedIconKeywords,
        icon_type: logoData?.metadata?.iconType,
        auto_layout: logoData.autoLayout,
        mode_type: modeType,
      },
    });

    const pngFileDataURL = await svgAsPngUri(logoNode, {
      scale,
    });
    const logoPngBlob = dataURLToBlob(pngFileDataURL);
    const faviconPngBlob = await domtoimage.toBlob(faviconNode);

    const zip = new JSZip();
    const includeSvgFile = !!logoData.elements.find(
      (element) => element.id === 'mark',
    ).svg;
    if (includeSvgFile) {
      zip.file('logo.svg', logoNode.outerHTML);
    }

    zip.file('logo.png', logoPngBlob);
    zip.file('favicon-32x32.png', faviconPngBlob);

    const zipFileWithLogos = await zip.generateAsync({ type: 'blob' });
    saveAs(zipFileWithLogos, 'logo-files.zip');
  };

  return {
    mainViewActiveTabId,
    isAiLogosBeingGenerated,
    isPurchaseModalOpen,
    createLogo,
    createAiLogos,
    downloadLogo,
  };
};
