import httpService from '@/api/httpService';

const GOOGLE_FONTS_API = `${
  import.meta.env.VITE_BACKEND_API_URL
}/logo-maker/google-fonts`;

export const getGoogleFonts = async ({ sort } = {}) => {
  const { data } = await httpService.get(GOOGLE_FONTS_API, {
    params: {
      sort,
    },
  });

  return data;
};
