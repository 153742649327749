import { ref } from 'vue';
import { defineStore } from 'pinia';
import { db } from '@/db';

export const useLogosStore = defineStore('logos', () => {
  const logosList = ref([]);
  const chosenLogo = ref(null);

  function updateLogo(logoIndex, newLogoData) {
    logosList.value = logosList.value.map((logoData, index) =>
      index === logoIndex ? newLogoData : logoData,
    );
  }

  function saveLogosToIndexDB() {
    db.logos.bulkPut(JSON.parse(JSON.stringify(logosList.value)));
  }

  function setChosenLogo(logo) {
    chosenLogo.value = logo;
  }

  return {
    chosenLogo,
    logosList,
    updateLogo,
    setChosenLogo,
    saveLogosToIndexDB,
  };
});
