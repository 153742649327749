export const BEARER_TOKEN = 'bearerToken';
export const JWT = 'jwt';
export const AUTH_TOKEN = 'auth_token';
export const TOKEN = 'token';
export const AUTH_TOKEN_PREFIX = 'Bearer ';

export const getAuthToken = () => localStorage.getItem(BEARER_TOKEN);
export const getBearerToken = () => `${AUTH_TOKEN_PREFIX}${getAuthToken()}`;
export const setAuthToken = (token: string) =>
  localStorage.setItem(BEARER_TOKEN, token);
