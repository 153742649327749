import httpService from '@/api/httpService';
export const LOGO_MAKER_API = `${
  import.meta.env.VITE_BACKEND_API_URL
}/logo-maker`;

export const generateLogos = async ({
  iconType,
  brandName,
  brandDescription,
  modelType,
  isColorful,
  clientPreferences,
}) => {
  const { data } = await httpService.post(
    `${LOGO_MAKER_API}/generate-logos?aiModelType=${modelType}`,
    {
      iconType,
      brandName,
      brandDescription,
      colorful: isColorful,
      clientPreferences,
    },
  );

  return data;
};
