<script setup>
import { ref, computed, watch } from 'vue';
import { logEvent } from '@/api/AmplitudeApi';
import { useLogo } from '@/use/useLogo';
import { useLogosStore } from '@/stores/logos';
import EditIcon from '@/assets/icons/pen-outline.svg';
import PurchaseModal from '@/components/PurchaseModal.vue';
import LogoPreview from '@/components/LogoPreview.vue';
import Modal from '@/components/LogoMakerModal.vue';
import Button from '@/components/LogoMakerButton.vue';
import AiGeneratorInputBar from '@/components/AiGeneratorInputBar.vue';
import LogoExamples from '@/components/LogoExamples.vue';
import EditorView from '@/components/EditorView.vue';
import CreditsCounter from '@/components/CreditsCounter.vue';
import AiWebsiteBuilderBanner from '@/components/AiWebsiteBuilderBanner.vue';
import ProgressBarLoader from '@/components/ProgressBarLoader.vue';
import { ENTER_EDIT } from '@/constants/amplitude';
import { MAIN_VIEW_TAB_AUTO } from '@/constants/mainView';
import {
  LOGO_CREATE_MODE_RESULTS,
  LOGO_DOWNLOAD_MODE_AUTO,
  LOGO_DOWNLOAD_MODE_EDIT,
} from '@/constants/events';
import RenderingEngineFavicon from '@/components/RenderingEngineFavicon.vue';

const emit = defineEmits(['download-logo']);

const {
  mainViewActiveTabId,
  isAiLogosBeingGenerated,
  isPurchaseModalOpen,
  createAiLogos,
  downloadLogo,
} = useLogo();
const logosStore = useLogosStore();

const purchaseModalLocation = computed(() => {
  return mainViewActiveTabId.value === MAIN_VIEW_TAB_AUTO
    ? 'ai_logo_maker_generate_logo_auto'
    : '';
});

const selectedLogoId = ref(null);
const logoRenderRefs = ref(null);
const faviconRenderRefs = ref(null);

const selectedLogoIndex = computed(() =>
  logosStore.logosList.findIndex(({ id }) => id === selectedLogoId.value),
);
const selectedLogoData = computed(() =>
  logosStore.logosList.find(({ id }) => id === selectedLogoId.value),
);

const showLogoExamples = computed(
  () => !logosStore.logosList?.length > 0 && !isAiLogosBeingGenerated.value,
);

const selectLogo = (id) => {
  selectedLogoId.value = id;
};
const unselectLogo = async () => {
  selectedLogoId.value = null;

  // Wait for the modal to close, before saving the logos to the indexDB
  // Otherwise, modal closing feels laggy and delayed
  setTimeout(() => {
    logosStore.saveLogosToIndexDB();
  }, 100);
};

const handleGenerateSubmit = () => {
  createAiLogos({ modeType: LOGO_CREATE_MODE_RESULTS });
};

const handleModalDownloadClick = (logoOptions) => {
  downloadLogo({ ...logoOptions, modeType: LOGO_DOWNLOAD_MODE_EDIT });

  emit('download-logo');
};

const handleDownloadClick = (logoData) => {
  const selectedLogo = logoRenderRefs.value.find(
    (item) => item.logoNode.svg.id === logoData.id,
  );

  const selectedLogoFavicon = faviconRenderRefs.value.find(
    (item) => item.faviconNode.id === logoData.id,
  );

  downloadLogo({
    logoData: logoData,
    logoNode: selectedLogo.logoNode.svg,
    faviconNode: selectedLogoFavicon.faviconNode,
    scale: 5,
    modeType: LOGO_DOWNLOAD_MODE_AUTO,
  });

  emit('download-logo');
};

watch(selectedLogoId, (value) => {
  if (value) {
    logEvent({
      name: ENTER_EDIT,
      properties: {
        logo_id: value,
        type: selectedLogoData.value.logoType,
        mode: mainViewActiveTabId.value,
        auto_layout: selectedLogoData.value.autoLayout,
      },
    });
  }
});
</script>

<template>
  <div class="generator-view">
    <AiGeneratorInputBar @submit="handleGenerateSubmit" />

    <CreditsCounter
      :location="purchaseModalLocation"
      is-disclaimer-shown
    />

    <div class="logo-list">
      <TransitionGroup name="logos-preview">
        <template v-if="isAiLogosBeingGenerated">
          <div
            v-for="index in 4"
            :key="`loader-${index}`"
            class="logo-list__loading-placeholder"
          />
          <div class="logo-list__loading-bar">
            <ProgressBarLoader :duration="30" />
          </div>
        </template>
        <div
          v-for="logoData in logosStore.logosList"
          :key="logoData.id"
          class="logo"
        >
          <div
            class="logo__overlay"
            @click="selectLogo(logoData.id)"
          >
            <div class="logo__edit-text">
              <EditIcon />
              <p>Edit logo</p>
            </div>
            <Button
              class="logo__button-download"
              size="medium"
              @click.stop="handleDownloadClick(logoData)"
            >
              Download
            </Button>
          </div>
          <LogoPreview
            ref="logoRenderRefs"
            class="logo__preview"
            :logo-data="logoData"
          />
          <!-- Favicon render is hidden and only used for favicon download -->
          <RenderingEngineFavicon
            ref="faviconRenderRefs"
            class="logo__favicon-render"
            :logo-data="logoData"
          />
        </div>
      </TransitionGroup>
      <LogoExamples v-if="showLogoExamples" />
    </div>

    <AiWebsiteBuilderBanner class="generator-view__ai-website-builder-banner" />
  </div>

  <Modal
    v-if="selectedLogoId"
    @click-outside="unselectLogo"
  >
    <EditorView
      class="editor-view-modal"
      :logo-data="selectedLogoData"
      is-in-modal
      @close="unselectLogo"
      @update-logo="logosStore.updateLogo(selectedLogoIndex, $event)"
      @download-logo="handleModalDownloadClick"
    />
  </Modal>

  <PurchaseModal
    v-if="isPurchaseModalOpen"
    :location="purchaseModalLocation"
    @close="isPurchaseModalOpen = false"
  />
</template>

<style lang="scss" scoped>
$logo-preview-size: 276px;
$logo-preview-gap: 32px;

.generator-view {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 36px;
  padding-bottom: 120px;
  background-color: var(--light);
  overflow-y: scroll;

  &__heading {
    margin-bottom: 8px;
    color: var(--dark);
  }

  &__subheading {
    margin-bottom: 32px;
    color: var(--dark);
    font-weight: 400;
  }

  &__ai-website-builder-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}

.logo {
  $this: &;

  cursor: pointer;
  border: 1px solid transparent;
  position: relative;
  width: $logo-preview-size;
  height: $logo-preview-size;
  border-radius: 24px;
  border: 1px solid var(--gray-border);

  &:hover {
    #{$this}__overlay {
      opacity: 1;
      background-color: rgba(54, 52, 77, 0.7);
      transition:
        background-color 150ms ease-in-out,
        opacity 150ms ease-in-out;
    }

    #{$this}__button-download {
      bottom: 16px;
      transition: bottom 150ms ease-in-out;
    }

    #{$this}__preview {
      transform: scale(1.05);
      transition: transform 150ms ease-in-out;
    }
  }

  &__favicon-render {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &__edit-text {
    position: absolute;
    display: flex;
    top: 45%;
    gap: 8px;
    color: var(--light);
    font-weight: 700;
  }

  &__button-download {
    position: absolute;
    bottom: 8px;
    transition: bottom 150ms ease-in-out;
  }

  &__overlay {
    display: flex;
    opacity: 0;
    z-index: 1;
    border-radius: 24px;
    height: 100%;
    width: 100%;
    justify-content: center;
    position: absolute;
    background-color: transparent;
    transition:
      background-color 150ms ease-in-out,
      opacity 150ms ease-in-out;
  }

  &__preview {
    width: 100%;
    height: 100%;
    transition: transform 150ms ease-in-out;
  }
}

.logo-list {
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 32px;
  width: 1200px;
  margin-top: 64px;

  &__loading-placeholder {
    width: $logo-preview-size;
    height: $logo-preview-size;
    border-radius: 24px;
    border: 1px solid var(--gray-border);
    background: var(--gray-light);
    opacity: 30%;
  }

  &__loading-bar {
    top: 94px;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    display: flex;
    justify-content: center;
  }
}
.editor-view-modal {
  width: 1052px;
  height: 694px;
}

@media screen and (max-width: 1320px) {
  .logo-list {
    width: 892px;
  }
}

@media screen and (max-width: 1012px) {
  .logo-list {
    width: 584px;
    max-width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 920px) {
  .logo-list {
    margin-top: 48px;
  }
}

@media screen and (max-width: 704px) {
  .generator-view {
    padding: 36px 16px 16px 16px;
  }

  .editor-view-modal {
    width: 100%;
    height: 100%;
  }
}

// ANIMATIONS:

.logos-preview-move,
.logos-preview-enter-active,
.logos-preview-leave-active {
  transition: all 0.4s cubic-bezier(0.55, 0, 0.1, 1);

  .logo__favicon-render {
    display: none;
  }
}

.logos-preview-leave-active {
  position: absolute;
}
</style>
