<template>
  <div class="logos-examples">
    <div class="logos-examples__info-container">
      <span>
        Example:
        {{ ' ' }}
        <span class="logos-examples__bold">
          “{{ LOGO_GROUPS[logoGroup].description }}”
        </span>
      </span>
      <span
        class="logos-examples__action-try-another"
        @click="changeLogos"
      >
        <span
          class="logos-examples__spinning-icon"
          :class="{ 'spin-active': isSpinAnimationActive }"
        >
          <SpinningArrowsIcon />
        </span>
        {{ ' ' }}
        Try another example
      </span>
    </div>
    <div class="logos-examples__examples-container">
      <div class="logos-examples__example-container">
        <img
          class="logos-examples__example-img"
          :src="LOGO_GROUPS[logoGroup].items[0]"
        >
      </div>

      <div class="logos-examples__example-container">
        <img
          class="logos-examples__example-img"
          :src="LOGO_GROUPS[logoGroup].items[1]"
        >
      </div>

      <div class="logos-examples__example-container">
        <img
          class="logos-examples__example-img"
          :src="LOGO_GROUPS[logoGroup].items[2]"
        >
      </div>

      <div class="logos-examples__example-container">
        <img
          class="logos-examples__example-img"
          :src="LOGO_GROUPS[logoGroup].items[3]"
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import SpinningArrowsIcon from '@/assets/icons/spinning-arrows.svg';
import { LOGO_GROUPS } from '@/constants/logos';

const logoGroup = ref(0);
const isSpinAnimationActive = ref(false);

const changeLogos = () => {
  isSpinAnimationActive.value = true;
  setTimeout(() => {
    isSpinAnimationActive.value = false;
  }, 500);

  logoGroup.value =
    logoGroup.value === LOGO_GROUPS.length - 1 ? 0 : logoGroup.value + 1;
};
</script>

<style lang="scss" scoped>
.logos-examples {
  display: flex;
  flex-direction: column;

  &__info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 24px;
  }

  &__action-try-another {
    display: inline-flex;
    gap: 8px;
    color: var(--primary);
    cursor: pointer;
  }

  &__bold {
    font-weight: 700;
  }

  &__examples-container {
    position: relative;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 32px;
  }

  &__example-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 276px;
    height: 276px;
    border-radius: 24px;
    border: 1px solid var(--gray-border);
  }

  &__example-img {
    max-height: 70%;
    max-width: 70%;
    height: auto;
  }

  &__spinning-icon {
    &.spin-active {
      animation: spin 0.5s linear;
    }
  }
}

@media screen and (max-width: 704px) {
  .logos-examples {
    &__info-container {
      flex-direction: column;
      gap: 4px;
      margin-bottom: 24px;
    }

    &__examples-container {
      justify-content: center;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  75% {
    transform: rotate(315deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
