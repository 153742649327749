<script setup>
import { ref, watch } from 'vue';
import { getSvgProps } from '@/utils/getSvgProps';

const props = defineProps({
  logoData: {
    type: Object,
    required: true,
  },
});

// AUTO LAYOUTING:
// autolayout compose logo by predefined logo layouts
const autoLayoutData = ref(null);

const logoSvgRef = ref();
defineExpose({ svg: logoSvgRef });

const logoElementsRefs = ref();
const computeAutoLayout = () => {
  try {
    const markData = props.logoData.elements.find(({ id }) => id === 'mark');
    const markContent = markData.svg || markData.image;
    const {
      aspectRatio: markAspectRatio,
      viewBox: markViewBox,
      innerHTML: markInnerHTML,
    } = markData.svg
      ? getSvgProps(markData.svg)
      : {
          aspectRatio: markData.width / (markData.height || markData.width),
          viewBox: null,
          innerHTML: null,
        };
    const markWidth = parseInt(markData.width, 10);
    const markBounding = markContent
      ? {
          width: markWidth,
          height: markWidth / markAspectRatio,
        }
      : { width: 0, height: 0 };

    const nameData = props.logoData.elements.find(({ id }) => id === 'name');
    const nameContent = nameData.svg;
    const {
      aspectRatio: nameAspectRatio,
      viewBox: nameViewBox,
      innerHTML: nameInnerHTML,
    } = nameData.svg
      ? getSvgProps(nameData.svg)
      : { aspectRatio: 1, viewBox: null, innerHTML: null };
    const nameWidth = parseInt(nameData.width, 10);
    const nameBounding = nameData.svg
      ? {
          width: nameWidth,
          height: nameWidth / nameAspectRatio,
        }
      : { width: 0, height: 0 };

    const sloganData = props.logoData.elements.find(
      ({ id }) => id === 'slogan',
    );
    const sloganContent = sloganData.svg;
    const {
      aspectRatio: sloganAspectRatio,
      viewBox: sloganViewBox,
      innerHTML: sloganInnerHTML,
    } = sloganData.svg
      ? getSvgProps(sloganData.svg)
      : { aspectRatio: 1, viewBox: null, innerHTML: null };
    const sloganWidth = parseInt(sloganData.width, 10);
    const sloganBounding =
      sloganData.text && sloganData.svg
        ? {
            width: sloganWidth,
            height: sloganWidth / sloganAspectRatio,
          }
        : { width: 0, height: 0 };

    const horizontalTextAndMarkSeparatingMargin =
      markContent && (nameContent || sloganContent) ? 16 : 0;
    const verticalTextAndMarkSeparatingMargin =
      markContent && (nameContent || sloganContent) ? 8 : 0;
    const nameAndSloganSeparatingMargin = nameContent && sloganContent ? 8 : 0;
    const spaceBetweenTextAndMark =
      markContent && (nameContent || sloganContent)
        ? parseInt(props.logoData.spaceBetweenTextAndMark, 10) || 0
        : 0;

    let logoWidth = null;
    let logoHeight = null;
    let markX = null;
    let markY = null;
    let nameX = null;
    let nameY = null;
    let sloganX = null;
    let sloganY = null;
    if (props.logoData.autoLayout === 'markTop') {
      logoWidth = Math.max(
        markBounding.width,
        nameBounding.width,
        sloganBounding.width,
      );
      logoHeight =
        markBounding.height +
        verticalTextAndMarkSeparatingMargin +
        nameBounding.height +
        nameAndSloganSeparatingMargin +
        sloganBounding.height +
        spaceBetweenTextAndMark;
      markX = (logoWidth - markBounding.width) / 2;
      markY = 0;
      nameX = (logoWidth - nameBounding.width) / 2;
      nameY =
        markBounding.height +
        verticalTextAndMarkSeparatingMargin +
        spaceBetweenTextAndMark;
      sloganX = (logoWidth - sloganBounding.width) / 2;
      sloganY =
        markBounding.height +
        verticalTextAndMarkSeparatingMargin +
        nameBounding.height +
        nameAndSloganSeparatingMargin +
        spaceBetweenTextAndMark;
    }
    if (props.logoData.autoLayout === 'markBottom') {
      logoWidth = Math.max(
        markBounding.width,
        nameBounding.width,
        sloganBounding.width,
      );
      logoHeight =
        nameBounding.height +
        verticalTextAndMarkSeparatingMargin +
        sloganBounding.height +
        verticalTextAndMarkSeparatingMargin +
        markBounding.height +
        spaceBetweenTextAndMark;
      markX = (logoWidth - markBounding.width) / 2;
      markY =
        nameBounding.height +
        verticalTextAndMarkSeparatingMargin +
        sloganBounding.height +
        verticalTextAndMarkSeparatingMargin +
        spaceBetweenTextAndMark;
      nameX = (logoWidth - nameBounding.width) / 2;
      nameY = 0;
      sloganX = (logoWidth - sloganBounding.width) / 2;
      sloganY = nameBounding.height + verticalTextAndMarkSeparatingMargin;
    }
    if (props.logoData.autoLayout === 'markLeft') {
      logoHeight = Math.max(
        markBounding.height,
        nameBounding.height +
          sloganBounding.height +
          nameAndSloganSeparatingMargin,
      );
      logoWidth =
        markBounding.width +
        Math.max(nameBounding.width, sloganBounding.width) +
        horizontalTextAndMarkSeparatingMargin +
        spaceBetweenTextAndMark;

      markX = 0;
      markY = (logoHeight - markBounding.height) / 2;
      nameX =
        markBounding.width +
        horizontalTextAndMarkSeparatingMargin +
        spaceBetweenTextAndMark;
      nameY = (logoHeight - (nameBounding.height + sloganBounding.height)) / 2;
      sloganX =
        markBounding.width +
        horizontalTextAndMarkSeparatingMargin +
        spaceBetweenTextAndMark;
      sloganY =
        (logoHeight -
          (nameBounding.height +
            sloganBounding.height +
            nameAndSloganSeparatingMargin)) /
          2 +
        nameBounding.height +
        nameAndSloganSeparatingMargin;
    }
    if (props.logoData.autoLayout === 'markRight') {
      logoHeight = Math.max(
        markBounding.height,
        nameBounding.height +
          sloganBounding.height +
          verticalTextAndMarkSeparatingMargin,
      );
      logoWidth =
        markBounding.width +
        Math.max(nameBounding.width, sloganBounding.width) +
        horizontalTextAndMarkSeparatingMargin +
        spaceBetweenTextAndMark;
      markX =
        Math.max(nameBounding.width, sloganBounding.width) +
        horizontalTextAndMarkSeparatingMargin +
        spaceBetweenTextAndMark;
      markY = (logoHeight - markBounding.height) / 2;
      nameX = 0;
      nameY = (logoHeight - (nameBounding.height + sloganBounding.height)) / 2;
      sloganX = 0;
      sloganY =
        (logoHeight -
          (nameBounding.height +
            sloganBounding.height +
            verticalTextAndMarkSeparatingMargin)) /
          2 +
        nameBounding.height +
        verticalTextAndMarkSeparatingMargin;
    }

    autoLayoutData.value = {
      width: logoWidth,
      height: logoHeight,
      mark: {
        x: markX,
        y: markY,
        width: markBounding.width,
        height: markBounding.height,
        viewBox: markViewBox,
        innerHTML: markInnerHTML,
      },
      name: {
        x: nameX,
        y: nameY,
        width: nameBounding.width,
        height: nameBounding.height,
        viewBox: nameViewBox,
        innerHTML: nameInnerHTML,
      },
      slogan: {
        x: sloganX,
        y: sloganY,
        width: sloganBounding.width,
        height: sloganBounding.height,
        viewBox: sloganViewBox,
        innerHTML: sloganInnerHTML,
      },
    };
  } catch (e) {
    console.error(e, props.logoData);
  }
};

watch(
  () => props.logoData,
  () => {
    if (!props.logoData.autoLayout) {
      return;
    }

    computeAutoLayout();
  },
  { deep: true, immediate: true },
);
</script>

<template>
  <svg
    ref="logoSvgRef"
    xmlns="http://www.w3.org/2000/svg"
    :width="autoLayoutData?.width || null"
    :height="autoLayoutData?.height || null"
    :viewBox="
      autoLayoutData
        ? `0 0 ${autoLayoutData.width} ${autoLayoutData.height}`
        : null
    "
  >
    <template
      v-for="element in logoData.elements"
      :key="element.id"
    >
      <svg
        v-if="['svg', 'text'].includes(element.type) && element.svg"
        :id="element.id"
        ref="logoElementsRefs"
        :viewBox="autoLayoutData?.[element.id]?.viewBox"
        :width="autoLayoutData?.[element.id]?.width"
        :height="autoLayoutData?.[element.id]?.height"
        :style="{ fill: element.color }"
        :x="autoLayoutData?.[element.id]?.x || 0"
        :y="autoLayoutData?.[element.id]?.y || 0"
      >
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          fill="none"
        />
        <g v-html="autoLayoutData?.[element.id]?.innerHTML" />
      </svg>
      <image
        v-else-if="element.type === 'image'"
        :id="element.id"
        ref="logoElementsRefs"
        :x="autoLayoutData?.[element.id]?.x || 0"
        :y="autoLayoutData?.[element.id]?.y || 0"
        :width="element.width"
        :height="element.height || element.width"
        :xlink:href="element.image"
      />
    </template>
  </svg>
</template>

<style lang="scss" scoped>
// Chrome crashes after clicking <svg> when it has <image> inside, and has pointer-events: auto;
// Didn't find root cause yet
svg {
  pointer-events: none;
}
</style>
