export const getLoginPageUrl = ({
  query = {},
  isRegister = false,
}: {
  query: {
    ref?: string;
  };
  isRegister?: boolean;
}) => {
  const redirectUrl = new URL(import.meta.env.VITE_LOGO_MAKER_URL);

  if (query.ref) {
    redirectUrl.searchParams.set('ref', query.ref);
  }

  window.open(
    `${import.meta.env.VITE_AUTH_URL}${isRegister ? '/register' : '/login'}?redirectUrl=${encodeURIComponent(redirectUrl.href)}`,
    '_self',
  );
};
