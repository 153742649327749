import httpService from '@/api/httpService';
export const NPS_API = `${import.meta.env.VITE_BACKEND_API_URL}/v3/nps`;

export const sendNpsScore = async ({ score, comment, formType, device }) =>
  await httpService.post(`${NPS_API}`, {
    score,
    comment,
    formType,
    device,
  });
