<template>
  <span
    class="loader"
    :style="{
      '--size': props.size,
      '--stroke-width': props.strokeWidth,
      '--color': props.color,
    }"
  />
</template>

<script setup>
const props = defineProps({
  size: {
    type: String,
    default: '24px',
  },
  strokeWidth: {
    type: String,
    default: '4px',
  },
  color: {
    type: String,
    default: 'var(--light)',
  },
});
</script>

<style lang="scss" scoped>
.loader {
  width: var(--size);
  height: var(--size);
  border: var(--stroke-width) solid var(--color);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
