<template>
  <div>
    <div class="credits-counter">
      You have
      <b>{{ creditsBalance }}</b>
      <span>{{ creditsBalance === 1 ? ' credit' : ' credits' }}</span>
      <span v-if="creditsBalance > 0"> left</span>
      <template v-if="showBuyCreditsTextButton">
        <span>.</span>
        <span
          class="credits-counter__buy"
          @click="isPurchaseModalOpen = true"
        >
          Buy credits
        </span>
      </template>
      <p
        v-if="isDisclaimerShown"
        class="credits-counter__disclaimer"
      >
        AI-generated logos may be misleading or inaccurate
      </p>
      <div
        v-if="showBuyCreditsButton"
        class="credits-counter__buy-button-container"
      >
        <Button
          data-qa="logomaker-buy-credits-button"
          size="medium"
          @click="isPurchaseModalOpen = true"
        >
          Buy credits
        </Button>
      </div>
    </div>
    <PurchaseModal
      v-if="isPurchaseModalOpen"
      :location="props.location"
      @close="isPurchaseModalOpen = false"
    />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useUserStore } from '@/stores/user';
import PurchaseModal from '@/components/PurchaseModal.vue';
import Button from '@/components/LogoMakerButton.vue';

const props = defineProps({
  location: {
    type: String,
    required: true,
  },
  isDisclaimerShown: {
    type: Boolean,
    default: false,
  },
  showButton: {
    type: Boolean,
    default: true,
  },
});

const userStore = useUserStore();

const isPurchaseModalOpen = ref(false);

const creditsBalance = computed(() => userStore.creditsBalance || 0);

const showBuyCreditsTextButton = computed(
  () => !props.showButton || creditsBalance.value > 0,
);

const showBuyCreditsButton = computed(
  () => props.showButton && creditsBalance.value === 0,
);
</script>

<style lang="scss" scoped>
.credits-counter {
  font-size: 14px;
  line-height: 1.7;
  color: var(--gray-dark);
  text-align: center;

  &__disclaimer {
    font-size: 12px;
    line-height: normal;
    color: var(--gray);
    margin-top: 4px;
  }

  &__buy {
    color: var(--primary);
    cursor: pointer;
  }

  &__buy-button-container {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }
}
</style>
