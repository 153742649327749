import { init, track } from '@amplitude/analytics-browser';

type LogEvent = {
  name: string;
  properties?: Record<string, unknown>;
};

export const initAmplitude = (userId: string) => {
  if (
    !import.meta.env.VITE_AMPLITUDE_BROWSER_API_KEY ||
    !import.meta.env.VITE_AMPLITUDE_ANALYTICS_URL ||
    import.meta.env.DEV
  ) {
    return;
  }

  init(import.meta.env.VITE_AMPLITUDE_BROWSER_API_KEY, {
    serverUrl: import.meta.env.VITE_AMPLITUDE_ANALYTICS_URL,
    defaultTracking: false,
    userId,
  });
};

export const logEvent = ({ name, properties }: LogEvent): void => {
  if (import.meta.env.DEV) {
    console.info(
      `%c [Amplitude]: Sending event - ${name}`,
      'color: skyblue; font-weight: bold;',
      properties,
    );

    return;
  }

  track(name, properties);
};
