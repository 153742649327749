import {
  createRouter,
  createWebHistory,
  type NavigationGuardNext,
  type RouteLocationNormalized,
} from 'vue-router';
import { useUserStore } from '@/stores/user';
import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/authStore';
import HomeView from '../views/HomeView.vue';
import Login from '../views/LoginView.vue';
import PaymentConfirmation from '../views/PaymentConfirmation.vue';
import PaymentFailed from '../views/PaymentFailed.vue';
import { AUTH_TOKEN, JWT, TOKEN, setAuthToken } from '@/utils/auth';

export const HOME_ROUTE_NAME = 'home';
export const LOGIN_ROUTE_NAME = 'login';
export const PAYMENT_CONFIRMATION_ROUTE_NAME = 'payment-confirmation';
export const PAYMENT_FAILED_ROUTE_NAME = 'payment-failed';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: HOME_ROUTE_NAME,
      component: HomeView,
    },
    {
      path: '/login',
      name: LOGIN_ROUTE_NAME,
      component: Login,
    },
    {
      path: '/payment-confirmation',
      name: PAYMENT_CONFIRMATION_ROUTE_NAME,
      component: PaymentConfirmation,
    },
    {
      path: '/payment-failed',
      name: PAYMENT_FAILED_ROUTE_NAME,
      component: PaymentFailed,
    },
  ],
});

const removeJWTQuery = (
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (!to.query[JWT] && !to.query[AUTH_TOKEN] && !to.query[TOKEN]) {
    return next();
  }

  const {
    [JWT]: _jwt,
    [AUTH_TOKEN]: _authToken,
    [TOKEN]: _token,
    ...otherQueries
  } = to.query;

  return next({
    ...to,
    query: otherQueries,
  });
};

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();
  const appStore = useAppStore();
  const authStore = useAuthStore();

  authStore.setRefLocation(to.query.ref as string);

  if (to.query.websiteUrl) {
    localStorage.setItem('websiteUrl', to.query.websiteUrl as string);
  }

  if (to.query[JWT]) {
    appStore.isLoading = true;

    setAuthToken(to.query[JWT] as string);

    await userStore.authorizeUser({ authToken: null, jwtToken: to.query[JWT] });
    await userStore.getUser();

    appStore.isLoading = false;

    removeJWTQuery(to, next);

    return;
  }

  if (to.query[AUTH_TOKEN]) {
    appStore.isLoading = true;

    await userStore.authorizeUser({
      authToken: to.query[AUTH_TOKEN],
      jwtToken: null,
    });
    await userStore.getUser();

    appStore.isLoading = false;

    removeJWTQuery(to, next);

    return;
  }

  await userStore.getUser();

  next();
});

export default router;
