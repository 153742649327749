export const ENTER_HOME = 'logo_maker.logo_maker.enter';
export const MODE_CHANGE = 'logo_maker.mode.changed';
export const CREATE_LOGO = 'logo_maker.create_logo.create';
export const TRY_CREATE_LOGO = 'logo_maker.create_logo.generate';
export const ENTER_EDIT = 'logo_maker.edit_logo.enter';
export const DOWNLOAD_LOGO = 'logo_maker.create_logo.download';
export const EDIT_LOGO = 'logo_maker.edit_logo.edit';
export const EDIT_LOGO_SEARCH_ICON = 'logo_maker.edit_logo.search_icon_library';
export const CHANGE_ICON_STYLE = 'logo_maker.icon_style.change';
export const SHOW_OFFER = 'logo_maker.new.offer_shown';
export const CHOOSE_OFFER = 'logo_maker.new.offer_chosen';
export const ENTER_TRY_HOSTINGER = 'logo_maker.try_hostinger.enter';
export const RATE_LOGO = 'logo_maker.logo_rating.sent';
export const LOGO_MAKER_FEEDBACK_SHOWN = 'logo_maker.feedback.survey_shown';
export const LOGO_MAKER_FEEDBACK_SENT = 'logo_maker.feedback.sent';
export const LOGO_MAKER_ENTER_PAYMENT_FAILED =
  'logo_maker.payment_failed.enter';
export const ENTER_SETTINGS = 'logo_maker.settings.enter';
export const COLORFUL_OPTION_CHANGED =
  'logo_maker.settings.colorful_option_changed';
