<script setup>
import { ref, watch } from 'vue';
import { RouterView } from 'vue-router';
import LoadingScreen from '@/components/LoadingScreen.vue';
import { useAppStore } from '@/stores/app';
import { useUserStore } from '@/stores/user';
import { initAmplitude } from './api/AmplitudeApi';
import { initializeExperiments } from '@/utils/experiments';

const isInitializingExperiments = ref(true);
const appStore = useAppStore();
const userStore = useUserStore();

const handleInitializeExperiments = async (userId) => {
  isInitializingExperiments.value = true;
  await initializeExperiments(userId).catch();
  isInitializingExperiments.value = false;
};

watch(
  () => userStore.user?.hPanelId,
  async (userId) => {
    await initAmplitude(userId);
    handleInitializeExperiments(userId);
  },
);
</script>

<template>
  <RouterView />
  <LoadingScreen v-if="appStore.isLoading || isInitializingExperiments" />
</template>
