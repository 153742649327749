import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { getCreditChargesEstimations } from '@/api/PaymentsApi';
import getSymbolFromCurrency from 'currency-symbol-map';

export const LOGO_MAKER_CREDITS_CHARGE_50 = 'logomaker-credits50';
const DEFAULT_CURRENCY = 'USD';
const DEFAULT_CHARGE_50_PRICE = '5.00';

export const useChargesStore = defineStore('charges', () => {
  const creditChargesEstimations = ref(null);

  const creditCharges = computed(() => {
    const { sub_total, currency_code } =
      creditChargesEstimations.value?.[LOGO_MAKER_CREDITS_CHARGE_50] || {};
    const name = '50 credits';
    const price = sub_total
      ? (sub_total / 100).toFixed(2)
      : DEFAULT_CHARGE_50_PRICE;
    const currency = currency_code || DEFAULT_CURRENCY;
    const currencySymbol = getSymbolFromCurrency(currency);

    return {
      [LOGO_MAKER_CREDITS_CHARGE_50]: {
        name,
        price,
        currency,
        currencySymbol,
      },
    };
  });

  async function getCreditCharges() {
    try {
      const data = await getCreditChargesEstimations();
      creditChargesEstimations.value = data;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    creditCharges,
    getCreditCharges,
  };
});
