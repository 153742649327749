<template>
  <div class="auth">
    Logging in
  </div>
</template>

<script setup lang="ts">
import { getLoginPageUrl } from '@/utils/getLoginPageUrl';
import { useRouter } from 'vue-router';

const router = useRouter();
const { query } = router.currentRoute.value;

getLoginPageUrl({ query });
</script>
