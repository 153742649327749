<template>
  <div
    v-if="appStore.isAiBuilderBannerOpen"
    class="banner"
  >
    Need a website?
    <a
      class="banner__link"
      href="https://builder.hostinger.com/ai-builder?ref=logo-maker"
      target="_blank"
      @click="
        logEvent({
          name: ENTER_TRY_HOSTINGER,
        })
      "
    >
      Try our new AI Website Builder for free
      <ArrowRight />
    </a>
    <Close
      class="banner__close"
      @click="appStore.isAiBuilderBannerOpen = false"
    />
  </div>
</template>

<script setup>
import ArrowRight from '@/assets/icons/arrow-right.svg';
import Close from '@/assets/icons/close.svg';
import { useAppStore } from '@/stores/app';
import { logEvent } from '@/api/AmplitudeApi';
import { ENTER_TRY_HOSTINGER } from '@/constants/amplitude';

const appStore = useAppStore();
</script>

<style lang="scss" scoped>
.banner {
  display: flex;
  padding: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: var(--gray-light);
  font-weight: bold;
  color: var(--gray-dark);
  gap: 8px;

  &__link {
    color: var(--primary);
    display: flex;
    align-items: center;
    gap: 4px;
    text-decoration: none;
  }

  &__close {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 32px;
    margin: auto;
    cursor: pointer;
  }
}

@media screen and (max-width: 704px) {
  .banner {
    display: block;
    padding: 12px 16px;
  }
}
</style>
