import Cookies from 'js-cookie';
import { Experiment, ExperimentClient } from '@amplitude/experiment-js-client';

const experimentsInstance: {
  amplitudeExperimentInstance: ExperimentClient | null;
} = {
  amplitudeExperimentInstance: null,
};

export const initializeExperiments = async (userId?: string) => {
  if (!import.meta.env.VITE_AMPLITUDE_EXPERIMENTS_API_KEY) {
    return;
  }

  experimentsInstance.amplitudeExperimentInstance = Experiment.initialize(
    import.meta.env.VITE_AMPLITUDE_EXPERIMENTS_API_KEY,
  );

  await experimentsInstance.amplitudeExperimentInstance.fetch({
    user_id: userId,
    device_id: Cookies.get('hostingerDeviceId'),
  });
};

export const EXPERIMENT_IDS = {} as const;

type ExperimentId = (typeof EXPERIMENT_IDS)[keyof typeof EXPERIMENT_IDS];

type ExperimentGetter = (
  experimentId: ExperimentId,
) => string | null | undefined;

export const getExperimentVariant: ExperimentGetter = (experimentId) => {
  const experimentVariant =
    experimentsInstance.amplitudeExperimentInstance?.variant(
      experimentId,
    ).value;

  return experimentVariant;
};

export const getIsExperimentActive = (
  experimentId: ExperimentId,
  variants?: string | string[],
  experimentVariantGetter: ExperimentGetter = getExperimentVariant,
): boolean => {
  const experimentVariant = experimentVariantGetter(experimentId);
  const customVariants = typeof variants === 'string' ? [variants] : variants;
  const preparedVariants = customVariants ?? ['on', '1', '0'];

  return preparedVariants.includes(experimentVariant || '');
};
