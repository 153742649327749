import { createApp } from 'vue';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';

import App from './App.vue';
import router from './router';

import './assets/css/main.css';

const app = createApp(App);

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    app,
    environment: import.meta.env.PROD ? 'production' : 'staging',
    allowUrls: [
      'logo-ai.hostinger.com',
      'logo-ai.zyro.com',
      'logo.hostinger.com',
      'logo.hostinger.io',
    ],
    dsn: import.meta.env.VITE_SENTRY_DSN,
    tracesSampleRate: 1,
    debug: import.meta.env.MODE === 'development',
  });
}

app.use(createPinia());
app.use(router);

app.mount('#app');
