<template>
  <div class="payment-confirmation">
    <Loader
      color="var(--primary)"
      size="40px"
      stroke-width="5px"
      class="payment-confirmation__loader"
    />
    <div>Processing your payment...</div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { confirmPayment } from '@/api/PaymentsApi';
import { PAYMENT_FAILED_ROUTE_NAME } from '@/router';
import Loader from '@/components/LogoMakerLoader.vue';

const router = useRouter();

const INTERVAL_BETWEEN_TRIES = 5000;
const MAX_TRIES = 10;
const tries = ref(0);
const token = sessionStorage.getItem('payment-token');

const handlePaymentError = () => {
  clearInterval(intervalId);
  router.push({ name: PAYMENT_FAILED_ROUTE_NAME });
};

const intervalId = setInterval(async () => {
  try {
    if (tries.value === MAX_TRIES) {
      handlePaymentError();
      return;
    }

    await confirmPayment({ token });
    clearInterval(intervalId);
    window.open(import.meta.env.VITE_LOGO_MAKER_URL, '_self');
  } catch (error) {
    console.error(error);
    tries.value += 1;
    if (error.response.status !== 402) {
      handlePaymentError();
    }
  }
}, INTERVAL_BETWEEN_TRIES);
</script>

<style lang="scss" scoped>
.payment-confirmation {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.33;

  &__loader {
    margin-bottom: 24px;
  }
}
</style>
