import httpService from '@/api/httpService';

export const getCreditChargesEstimations = async () => {
  const { data } = await httpService.get(
    `${import.meta.env.VITE_BACKEND_API_URL}/logo-maker/credits/estimate`,
  );

  return data;
};

export const getPaymentLink = async ({
  backUrl = import.meta.env.VITE_LOGO_MAKER_URL,
  cancelUrl = `${import.meta.env.VITE_LOGO_MAKER_URL}/payment-failed`,
  confirmationURL = `${
    import.meta.env.VITE_LOGO_MAKER_URL
  }/payment-confirmation`,
  itemId,
} = {}) => {
  const { data } = await httpService.post(
    `${import.meta.env.VITE_BACKEND_API_URL}/logo-maker/credits/purchase`,
    { backURL: backUrl, cancelURL: cancelUrl, confirmationURL, itemId },
  );

  return data;
};

export const confirmPayment = async ({ token }) => {
  const { data } = await httpService.post(
    `${import.meta.env.VITE_BACKEND_API_URL}/logo-maker/credits/confirm`,
    { token },
  );

  return data;
};
