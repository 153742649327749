<script setup>
import { ref, computed, watch } from 'vue';
import { recommendedFonts } from '@/data/fonts';
import { debounce } from 'lodash';
import LayoutLeftIcon from '@/assets/icons/layout-left.svg';
import LayoutRightIcon from '@/assets/icons/layout-right.svg';
import LayoutTopIcon from '@/assets/icons/layout-top.svg';
import LayoutBottomIcon from '@/assets/icons/layout-bottom.svg';
import CloseIcon from '@/assets/icons/close.svg';
import RenderingEngine from '@/components/RenderingEngine.vue';
import RenderingEngineFavicon from '@/components/RenderingEngineFavicon.vue';
import Button from '@/components/LogoMakerButton.vue';
import ColorPicker from '@/components/ColorPicker.vue';
import DownloadModal from '@/components/DownloadModal.vue';
import AiWebsiteBuilderBanner from '@/components/AiWebsiteBuilderBanner.vue';
import { useTextToSvg } from '@/use/useTextToSvg';
import { logEvent } from '@/api/AmplitudeApi';
import { EDIT_LOGO } from '@/constants/amplitude';
import { LOGO_DOWNLOAD_MODE_SELF } from '@/constants/events';
import { useLogosStore } from '@/stores/logos';

const EDITOR_TAB_TEXT = 'text';
const EDITOR_TAB_LAYOUT = 'layout';

const props = defineProps({
  logoData: {
    type: Object,
    required: true,
  },
  isAiBuilderBannerShown: {
    type: Boolean,
    default: false,
  },
  isInModal: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update-logo', 'download-logo', 'close']);

const { googleFontsList, getSvg } = useTextToSvg();

const editedLogoData = ref(JSON.parse(JSON.stringify(props.logoData)));

const editorTabs = computed(() => {
  return [
    {
      id: EDITOR_TAB_TEXT,
      label: 'Text',
    },
    {
      id: EDITOR_TAB_LAYOUT,
      label: 'Layout',
    },
  ];
});

const logosStore = useLogosStore();

const activeTab = ref(editorTabs.value[0].id);
const isFontSelectOpen = ref(false);
const isNameColorPickerOpen = ref(false);
const isSloganColorPickerOpen = ref(false);
const isLogoColorPickerOpen = ref(false);
const isDownloadModalOpen = ref(false);

const nameData = computed(() => {
  return editedLogoData.value.elements.find(({ id }) => id === 'name');
});
const sloganData = computed(() => {
  return editedLogoData.value.elements.find(({ id }) => id === 'slogan');
});
const markData = computed(() => {
  return editedLogoData.value.elements.find(({ id }) => id === 'mark');
});
const logoColor = computed(() => {
  return editedLogoData.value.elements.find(({ id }) => id === 'name').color;
});
const hasIconAndText = computed(
  () =>
    (markData.value.image || markData.value.svg) &&
    (nameData.value.text || sloganData.value.text),
);

const updateLogoProperty = async ({ property, value }) => {
  editedLogoData.value[property] = value;

  logEditorAction({
    property: `logo-${property}`,
    value,
  });
};
const updateAllLogoElementsProperty = ({ property, value }) => {
  editedLogoData.value.elements.forEach((element, index) => {
    editedLogoData.value.elements[index][property] = value;
  });
  logEditorAction({
    property: `${property}-all`,
    value,
  });
};
const updateLogoElementProperty = ({ elementType, property, value }) => {
  const logoElementIndex = editedLogoData.value.elements.findIndex(
    (element) => element.id === elementType,
  );
  editedLogoData.value.elements[logoElementIndex][property] = value;

  logEditorAction({
    property: `element-${elementType}-${property}`,
    value,
  });
};
const updateMarkWidth = (value) => {
  const aspectRatio = markData.value.width / markData.value.height;
  const height = value / aspectRatio;
  updateLogoElementProperty({
    elementType: 'mark',
    property: 'width',
    value,
  });
  updateLogoElementProperty({
    elementType: 'mark',
    property: 'height',
    value: height,
  });
  logEditorAction({
    property: `element-mark-width`,
    value,
  });
};
const updateLogoText = async ({ elementType, value }) => {
  const logoTextIndex = editedLogoData.value.elements.findIndex(
    (element) => element.id === elementType,
  );
  const { fontFamily, fontSize } = editedLogoData.value.elements[logoTextIndex];

  let svg = null;
  let width = null;
  if (value) {
    const data = await getSvg({
      text: value,
      fontFamily: fontFamily,
      fontSize: fontSize,
    });
    svg = data.svg;
    width = data.width;
  }

  editedLogoData.value.elements[logoTextIndex].text = value;
  editedLogoData.value.elements[logoTextIndex].svg = svg;
  editedLogoData.value.elements[logoTextIndex].width = width;

  logEditorAction({
    property: `element-${elementType}-text`,
    value,
  });
};
const updateLogoFont = (value) => {
  editedLogoData.value.elements.forEach(async (element, index) => {
    if (element.type === 'text' && element.text) {
      const { svg, width } = await getSvg({
        text: element.text,
        fontFamily: value,
        fontSize: element.fontSize,
      });
      editedLogoData.value.elements[index].fontFamily = value;
      editedLogoData.value.elements[index].svg = svg;
      editedLogoData.value.elements[index].width = width;
    }
  });

  isFontSelectOpen.value = false;

  logEditorAction({
    property: `logo-font`,
    value,
  });
};
const logEditorAction = debounce(({ property, value }) => {
  logEvent({
    name: EDIT_LOGO,
    properties: {
      property,
      value,
    },
  });
}, 700);

const logoRenderRef = ref();
const faviconRenderRef = ref();
const handleDownloadButtonClick = function () {
  logosStore.setChosenLogo(editedLogoData.value);
  emit('download-logo', {
    logoNode: logoRenderRef.value.svg,
    faviconNode: faviconRenderRef.value.faviconNode,
    logoData: editedLogoData.value,
    scale: 5,
    modeType: LOGO_DOWNLOAD_MODE_SELF,
  });
};

const fontRecommendations = ref([]);
const setFontRecommendations = async () => {
  fontRecommendations.value = await Promise.all(
    recommendedFonts.map(async (fontFamily) => {
      const { svg } = await getSvg({
        text: nameData.value.text || 'Logo text',
        fontFamily,
        fontSize: 100,
      });

      return {
        fontFamily,
        svg,
      };
    }),
  );
};
watch(
  () => nameData.value.text,
  debounce(() => {
    setFontRecommendations();
  }, 300),
  {
    immediate: true,
  },
);

watch(
  () => editedLogoData.value,
  debounce(() => {
    emit('update-logo', JSON.parse(JSON.stringify(editedLogoData.value)));
  }, 200),
  { deep: true },
);
</script>

<template>
  <div class="editor-view">
    <div
      v-if="isInModal"
      class="editor-view__header"
    >
      <div class="editor-view__header-buttons">
        <Button
          data-qa="download-button"
          class="editor-view__download-button"
          size="medium"
          @click="handleDownloadButtonClick"
        >
          Download
        </Button>
        <CloseIcon
          class="editor-view__close"
          @click="$emit('close')"
        />
      </div>
    </div>
    <div
      class="editor-view__content"
      :class="{ 'editor-view__content--modal': isInModal }"
    >
      <div class="editor-view__left">
        <div
          v-if="isInModal"
          class="editor-view__tabs"
        >
          <div
            v-for="tab in editorTabs"
            :key="tab.id"
            class="editor-view__tabs-tab"
            :class="{ active: activeTab === tab.id }"
            @click="activeTab = tab.id"
          >
            {{ tab.label }}
          </div>
        </div>
        <div class="editor-view__settings">
          <template v-if="activeTab === EDITOR_TAB_TEXT">
            <div class="editor-view__input-container">
              <div class="editor-view__input-label">
                Name
              </div>
              <div class="editor-view__input-and-color">
                <input
                  class="editor-view__text-input"
                  placeholder="Type name..."
                  :value="nameData.text"
                  @input="
                    updateLogoText({
                      elementType: 'name',
                      value: $event.target.value,
                    })
                  "
                >
                <ColorPicker
                  :is-open="isNameColorPickerOpen"
                  :model-value="nameData.color"
                  @toggle="isNameColorPickerOpen = !isNameColorPickerOpen"
                  @update:model-value="
                    updateLogoElementProperty({
                      elementType: 'name',
                      property: 'color',
                      value: $event,
                    })
                  "
                  @click-outside="isNameColorPickerOpen = false"
                />
              </div>
            </div>
            <div class="editor-view__input-container">
              <div class="editor-view__input-label">
                Slogan
              </div>
              <div class="editor-view__input-and-color">
                <input
                  class="editor-view__text-input"
                  placeholder="Type slogan..."
                  :value="sloganData.text"
                  @input="
                    updateLogoText({
                      elementType: 'slogan',
                      value: $event.target.value,
                    })
                  "
                >
                <ColorPicker
                  :is-open="isSloganColorPickerOpen"
                  :model-value="sloganData.color"
                  @toggle="isSloganColorPickerOpen = !isSloganColorPickerOpen"
                  @update:model-value="
                    updateLogoElementProperty({
                      elementType: 'slogan',
                      property: 'color',
                      value: $event,
                    })
                  "
                  @click-outside="isSloganColorPickerOpen = false"
                />
              </div>
            </div>
            <div class="editor-view__input-container">
              <div class="editor-view__input-label">
                Font family
              </div>
              <select
                class="editor-view__font-select"
                @change="updateLogoFont($event.target.value)"
              >
                <option
                  v-for="font in googleFontsList"
                  :key="font.family"
                  :selected="nameData.fontFamily === font.family"
                  :value="font.family"
                >
                  {{ font.family }}
                </option>
              </select>
            </div>
            <div class="editor-view__input-label">
              Font recommendations
            </div>
            <div class="editor-view__font-recommendations">
              <div
                v-for="font in fontRecommendations"
                :key="font.fontFamily"
                class="editor-view__font-recommendation"
                @click="updateLogoFont(font.fontFamily)"
                v-html="font.svg"
              />
            </div>
          </template>
          <template v-if="activeTab === EDITOR_TAB_LAYOUT">
            <div
              v-if="!hasIconAndText"
              class="editor-view__empty"
            >
              Logo needs to have icon and text to use layout settings
            </div>
            <div
              v-if="hasIconAndText"
              class="editor-view__input-container"
            >
              <div class="editor-view__input-label">
                Icon scale
              </div>
              <div class="editor-view__mark-width-input-container">
                <input
                  type="range"
                  class="editor-view__mark-width-input"
                  min="20"
                  max="200"
                  :value="markData.width"
                  @input="updateMarkWidth($event.target.value)"
                >
              </div>
            </div>
            <div
              v-if="hasIconAndText"
              class="editor-view__input-container"
            >
              <div class="editor-view__input-label">
                Space between icon and text
              </div>
              <div class="editor-view__mark-width-input-container">
                <input
                  type="range"
                  class="editor-view__mark-width-input"
                  min="-100"
                  max="100"
                  :value="editedLogoData.spaceBetweenTextAndMark || 0"
                  @input="
                    updateLogoProperty({
                      property: 'spaceBetweenTextAndMark',
                      value: $event.target.value,
                    })
                  "
                >
              </div>
            </div>
            <div
              v-if="hasIconAndText"
              class="editor-view__input-container editor-view__input-container--horizontal"
            >
              <div class="editor-view__input-label">
                Layout
              </div>
              <div class="editor-view__layout-input">
                <button
                  class="editor-view__layout-button"
                  :class="{ active: editedLogoData.autoLayout === 'markLeft' }"
                  @click="
                    updateLogoProperty({
                      property: 'autoLayout',
                      value: 'markLeft',
                    })
                  "
                >
                  <LayoutLeftIcon />
                </button>
                <button
                  class="editor-view__layout-button"
                  :class="{ active: editedLogoData.autoLayout === 'markRight' }"
                  @click="
                    updateLogoProperty({
                      property: 'autoLayout',
                      value: 'markRight',
                    })
                  "
                >
                  <LayoutRightIcon />
                </button>
                <button
                  class="editor-view__layout-button"
                  :class="{ active: editedLogoData.autoLayout === 'markTop' }"
                  @click="
                    updateLogoProperty({
                      property: 'autoLayout',
                      value: 'markTop',
                    })
                  "
                >
                  <LayoutTopIcon />
                </button>
                <button
                  class="editor-view__layout-button"
                  :class="{
                    active: editedLogoData.autoLayout === 'markBottom',
                  }"
                  @click="
                    updateLogoProperty({
                      property: 'autoLayout',
                      value: 'markBottom',
                    })
                  "
                >
                  <LayoutBottomIcon />
                </button>
              </div>
            </div>
            <div
              v-if="hasIconAndText && markData.type === 'svg'"
              class="editor-view__input-container editor-view__input-container--horizontal"
            >
              <div class="editor-view__input-label">
                Text and icon color
              </div>

              <ColorPicker
                :is-open="isLogoColorPickerOpen"
                :model-value="logoColor"
                @toggle="isLogoColorPickerOpen = !isLogoColorPickerOpen"
                @update:model-value="
                  updateAllLogoElementsProperty({
                    property: 'color',
                    value: $event,
                  })
                "
                @click-outside="isLogoColorPickerOpen = false"
              />
            </div>
          </template>
        </div>
      </div>
      <div class="editor-view__right">
        <AiWebsiteBuilderBanner
          v-if="isAiBuilderBannerShown"
          class="editor-view__ai-website-builder-banner"
        />
        <div
          class="editor-view__logo-preview-container"
          data-qa="editor-logo-preview"
        >
          <RenderingEngine
            ref="logoRenderRef"
            class="editor-view__logo-preview"
            :logo-data="editedLogoData"
          />
          <!-- Favicon render is hidden and only used for favicon download -->
          <RenderingEngineFavicon
            ref="faviconRenderRef"
            class="editor-view__favicon-render"
            :logo-data="editedLogoData"
          />
        </div>
        <Button
          v-if="!isInModal"
          data-qa="download-button"
          class="editor-view__download-button"
          size="xlarge"
          @click="handleDownloadButtonClick"
        >
          Download
        </Button>
      </div>
    </div>
    <DownloadModal
      v-if="isDownloadModalOpen"
      @download="
        emit('download-logo', {
          svgNode: logoRenderRef.svg,
          logoData: editedLogoData,
          scale: 1,
        })
      "
      @close="isDownloadModalOpen = false"
    />
  </div>
</template>

<style lang="scss" scoped>
.editor-view {
  $this: &;

  &__empty {
    font-size: 14px;
  }

  &__content {
    position: relative;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    display: grid;
    width: 100%;
    height: 100%;
    border-radius: 0 0 16px 16px;
    background: var(--light);
    gap: 16px;
    padding: 16px;
    overflow: hidden;
    flex-grow: 1;
    width: 100%;

    &--modal {
      display: flex;

      #{$this}__left {
        max-width: 400px;
      }
    }
  }

  &__header {
    background: var(--light);
    border-radius: 16px 16px 0 0;
    padding: 8px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid var(--gray-border);

    &--empty-block {
      flex: 1;
    }

    &--title {
      flex: 2;
    }
  }

  &__header-buttons {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__left {
    display: flex;
    flex-direction: column;
    align-items: start;
    flex-grow: 1;
    flex-shrink: 0;
    overflow-y: auto;
  }

  &__tabs {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
    border-radius: 8px;
    border: 1px solid var(--gray-border);
    background-color: var(--gray-light);

    &-tab {
      flex-grow: 1;
      font-size: 16px;
      line-height: 1.5;
      font-weight: 700;
      color: var(--gray);
      text-align: center;
      height: 100%;
      padding: 10px 16px;
      cursor: pointer;
      border-radius: 8px;

      &:hover {
        color: var(--dark);
      }

      &.active {
        color: var(--dark);
        background-color: var(--light);
      }
    }
  }

  .editor-view__settings {
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--gray-border);
    background-color: var(--gray-light);
    padding: 16px;
    overflow-y: auto;
    max-height: 78vh;
    height: 100%;
  }

  &__right {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;
    padding: 36px;
  }

  &__ai-website-builder-banner {
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: start;
    border-radius: 8px;
    gap: 4px;
  }

  &__logo-preview-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  &__logo-preview {
    height: 80%;
    width: 80%;
    z-index: 2;
    position: relative;
  }

  &__favicon-render {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &__separator {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid var(--gray-border);
    margin-top: 6px;
    margin-bottom: 12px;
  }

  &__input-container {
    width: 100%;

    &--horizontal {
      display: flex;
      align-items: start;
      flex-direction: column;
      justify-content: space-between;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__text-input {
    font-size: 14px;
    line-height: 1.71;
    color: var(--gray-dark);
    padding: 12px 16px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--gray-border);

    &::placeholder {
      color: var(--gray);
    }
  }

  &__input-label {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 8px;
    line-height: 1.71;
    color: var(--dark);
  }

  &__input-label:not(&__input-label--horizontal) &__input-label {
    margin-bottom: 4px;
  }

  &__input-and-color {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__font-recommendations {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    gap: 8px;
  }

  &__font-recommendation {
    width: 100%;
    height: 84px;
    border-radius: 8px;
    border: 1px solid var(--gray-border);
    background-color: var(--light);
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: var(--gray-light);
    }

    &:deep(svg) {
      max-width: 70%;
      max-height: 70%;
      height: auto;
    }
  }

  &__icon-input {
    display: flex;
    align-items: center;
  }

  &__icon-preview {
    width: 72px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    pointer-events: none; // chrome crashes on base64 image click
  }

  &__icon-color {
    margin-left: 8px;
  }

  &__icon-preview :deep(svg) {
    width: 62px;
    height: auto;
  }

  &__color-picker-button {
    height: 50px;
    width: 100%;
    border: 1px solid var(--gray-border);
    border-radius: 4px;
  }

  &__layout-button {
    padding: 12px;
    border-radius: 4px;
    border: 1px solid var(--gray-border);
    background-color: var(--light);
    cursor: pointer;

    &.active {
      border: 2px solid var(--primary);
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__font-input {
    width: 100%;
    padding: 12px 16px;
    font-size: 14px;
    line-height: 1.71;
    border-radius: 4px;
    border: 1px solid var(--gray-border);
    display: flex;
    background: var(--light);
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  &__font-select {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid var(--gray-border);
    width: 100%;
  }

  &__mark-width-input-container {
    font-size: 16px;
    display: flex;
    gap: 6px;
    height: 16px;
  }

  &__mark-width-input {
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    margin-top: 6px;
    background-color: var(--primary-light);

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 16px;
      height: 16px;
      background-color: var(--primary);
      border-radius: 50%;
      cursor: pointer;
      box-shadow: 0px 0px 12px rgba(29, 30, 32, 0.16);
    }
  }

  &__download-button {
    flex-shrink: 0;
  }

  &__close {
    fill: var(--gray-dark);
    margin: 8px;
    cursor: pointer;
  }
}

.font-list {
  width: 212px;
  border-radius: 4px;
  background: var(--light);
  border: 1px solid var(--gray-border);
  padding: 6px 0;
  overflow-y: auto;

  &__item {
    padding: 4px 16px;
    font-weight: 700;
    cursor: pointer;

    &:hover {
      background-color: var(--gray-light);
    }
  }
}

@media screen and (max-width: 704px) {
  .editor-view {
    $this: &;

    &__content {
      width: 100%;
      height: 100%;
      border-radius: 0px;
      padding: 8px;
      overflow-y: auto;
      grid-auto-flow: row;
      grid-auto-rows: 1fr 3fr;

      &--modal {
        flex-direction: column;

        #{$this}__left {
          max-width: unset;
        }

        #{$this}__right {
          max-height: 230px;
        }
      }
    }

    &__left {
      order: 2;
      width: 100%;
      overflow-y: auto;
      flex: 1;
      align-items: center;
    }

    &__title {
      display: none;
    }

    &__right {
      padding: 0;
      flex-direction: row;
      border-bottom: 1px solid var(--gray-border);
    }

    &__logo-preview-container {
      height: 100%;
      margin-right: auto;
      margin-left: auto;
      flex-grow: 0;
    }

    &__download {
      width: 260px;
    }

    &__ai-website-builder-banner {
      display: none;
    }
  }
}
</style>
