export const getSvgProps = (svg) => {
  const parser = new DOMParser();
  const { documentElement } = parser.parseFromString(svg, 'image/svg+xml');

  const errorNode = documentElement.querySelector('parsererror');
  if (errorNode) {
    console.error(errorNode);
    return { width: 0, height: 0, aspectRatio: 0, viewBox: '0 0 0 0' };
  }

  const width =
    documentElement.width.baseVal.value ||
    documentElement.viewBox.baseVal.width;
  const height =
    documentElement.height.baseVal.value ||
    documentElement.viewBox.baseVal.height;
  const aspectRatio = width / height;

  return {
    viewBox: `0 0 ${width} ${height}`,
    innerHTML: documentElement.innerHTML,
    aspectRatio,
    width,
    height,
  };
};
