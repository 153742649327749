<template>
  <Teleport to="body">
    <div
      v-if="isBackdropVisible"
      class="modal-backdrop"
    />
    <div
      class="modal-container"
      @click.self="$emit('click-outside')"
    >
      <slot />
    </div>
  </Teleport>
</template>

<script setup>
import { onBeforeUnmount, onMounted } from 'vue';

defineProps({
  isBackdropVisible: {
    type: Boolean,
    default: true,
  },
});

defineEmits(['click-outside']);

onMounted(() => {
  document.documentElement.style.overflow = 'hidden';
});

onBeforeUnmount(() => {
  document.documentElement.style.overflow = 'auto';
});
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(54, 52, 77, 0.7);
  z-index: 100;
}
.modal-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
</style>
