<template>
  <div
    class="logo-preview"
    :class="{
      'logo-preview--transparent-background': hasTransparentBackground,
    }"
  >
    <RenderingEngine
      :id="logoData.id"
      ref="renderingEngineRef"
      class="logo-preview__logo"
      :logo-data="props.logoData"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import RenderingEngine from '@/components/RenderingEngine.vue';

const renderingEngineRef = ref(null);

defineExpose({ logoNode: renderingEngineRef });

const props = defineProps({
  logoData: {
    type: Object,
    required: true,
  },
  hasTransparentBackground: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss" scoped>
.logo-preview {
  width: 276px;
  height: 276px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 210%;
  background-position: center;
  border-radius: 8px;
  user-select: none;

  &--transparent-background {
    background: url(@/assets/images/transparent-pattern.png);
    border: 2px solid var(--gray-border);
  }

  &__logo {
    max-height: 70%;
    width: 70%;
    height: auto;
  }
}
</style>
