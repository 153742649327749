import cupcake1 from '@/assets/images/cupcake-1.png';
import cupcake2 from '@/assets/images/cupcake-2.png';
import cupcake3 from '@/assets/images/cupcake-3.png';
import cupcake4 from '@/assets/images/cupcake-4.png';

import yogastudio1 from '@/assets/images/yoga-studio-1.png';
import yogastudio2 from '@/assets/images/yoga-studio-2.png';
import yogastudio3 from '@/assets/images/yoga-studio-3.png';
import yogastudio4 from '@/assets/images/yoga-studio-4.png';

import carservice1 from '@/assets/images/car-service-1.png';
import carservice2 from '@/assets/images/car-service-2.png';
import carservice3 from '@/assets/images/car-service-3.png';
import carservice4 from '@/assets/images/car-service-4.png';

interface ILogoGroup {
  description: string;
  items: string[];
}

type ILogoGroups = ILogoGroup[];

export const LOGO_GROUPS: ILogoGroups = [
  {
    description: 'yoga studio',
    items: [yogastudio1, yogastudio2, yogastudio3, yogastudio4],
  },
  {
    description: 'cupcake',
    items: [cupcake1, cupcake2, cupcake3, cupcake4],
  },
  {
    description: 'car service',
    items: [carservice1, carservice2, carservice3, carservice4],
  },
];
